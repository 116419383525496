
export default {
  data () {
    return {
      loading: false,
      tableData: [],
      pageOptions: {
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: 10,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
        total: 0
      }
    }
  }
}
