<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">退票单查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="退票航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="cities.depCity"
                :arrCity="cities.arrCity"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.dateType',
                  {
                    initialValue: '0',
                  },
                ]"
              >
                <a-select-option value="0">申请时间</a-select-option>
                <a-select-option value="1">起飞时间</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                size="small"
                v-decorator="['searchForm.rangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="退票单号"
                v-decorator="[
                  'searchForm.refundNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="订单号"
                v-decorator="[
                  'searchForm.orderNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="退票航班号"
                v-decorator="[
                  'searchForm.flightNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="票号"
                v-decorator="[
                  'searchForm.ticketNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.idType',
                  {
                    initialValue: '0',
                  },
                ]"
              >
                <a-select-option value="0">旅客身份证号</a-select-option>
                <a-select-option value="1">旅客姓名</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="旅客信息"
                v-decorator="[
                  'searchForm.certInfo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="退票状态">
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.refundStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option
                v-for="(status) in refundStatusOptions"
                :key="status.value"
                :value="status.value">{{ status.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
              <!-- <a-button size="small" type="info"> 导出 </a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{x: 'max-content'}"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            :loading="loading"
            @change="onTableChange"
          >
            <template slot="index" slot-scope="index">
              {{ index  }}
            </template>
            <template slot="refundNo" slot-scope="refundNo, row">
              <router-link
                :to="{
                  path: '/orderManager/refundDetail',
                  query: { id: row.id },
                }"
                tag="a"
                target="_blank"
                >{{ refundNo }}</router-link
              >
            </template>
            <template slot="segments" slot-scope="segments">
              <div v-for="(item, idx) in segments" :key="idx">
                {{ item.orgCity }}-{{ item.dstCity }}
              </div>
            </template>
            <template slot="createdTime" slot-scope="createdTime">
              <div>{{ createdTime }}</div>
            </template>
            <template slot="refundType" slot-scope="refundType">
              <div>{{ refundType | refundType }}</div>
            </template>
            <template slot="refundStatus" slot-scope="refundStatus">
              <div>{{ refundStatus | refundStatus }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'
// import { validate } from '@/utils/validate'
import { refundStatus as refundStatusOptions } from '@/dicts/order'
import { refundStatus, refundType } from '@/filters/order'
import { airCity } from '@/filters/flight'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'

import { fetchRefundList } from '@/api/order'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '退票单号',
    dataIndex: 'refundNo',
    scopedSlots: { customRender: 'refundNo' }
  },
  {
    title: '订单号',
    dataIndex: 'orderNo'
  },
  {
    title: '退票航段',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  },
  {
    title: '提交人',
    dataIndex: 'submitUser'
  },
  {
    title: '退票数量',
    dataIndex: 'refundPassengerCount'
  },
  {
    title: '机票总价',
    dataIndex: 'totalNetAmount'
  },
  {
    title: '燃油费',
    dataIndex: 'totalFuelTaxAmount'
  },
  {
    title: '机场费',
    dataIndex: 'totalAirportTaxAmount'
  },
  {
    title: '退票类型',
    dataIndex: 'refundType',
    scopedSlots: { customRender: 'refundType' }
  },
  {
    title: '退款时间',
    dataIndex: 'createTime'
  },
  {
    title: '状态',
    dataIndex: 'refundStatus',
    scopedSlots: { customRender: 'refundStatus' }
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    let segments = []

    if (col.segments) {
      segments = col.segments.map((seg) => {
        const { depCode, arrCode } = seg
        return { orgCity: airCity(depCode), dstCity: airCity(arrCode) }
      })
    }

    return {
      key: col.orderNo,
      index: page * size + (index + 1),
      ...col,
      ...{
        segments: segments
      }
    }
  })
}

export default {
  name: 'refundSearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [tableBaseMixin, orderSearchMixin],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      columns,
      refundStatusOptions,
      cities: {
        depCity: '',
        arrCity: ''
      },
      params: {
        arrCode: '',
        depCode: '',
        departureDateEnd: '',
        departureDateStart: '',
        orderNo: '',
        flightNo: '',
        outUserId: '',
        ticketNo: '',
        refundEndDate: '',
        refundNo: '',
        refundStartDate: '',
        refundStatus: '',
        refundType: '',
        page: 0,
        size: 10
      }
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await fetchRefundList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message || e)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm
      const { cities } = this

      const params = {
        depCode: cities.depCity,
        arrCode: cities.arrCity,
        departureDateEnd: '',
        departureDateStart: '',
        orderNo: fields.orderNo,
        flightNo: fields.flightNo,
        ticketNo: fields.ticketNo,
        refundEndDate: '',
        refundNo: fields.refundNo,
        refundStartDate: '',
        refundStatus: fields.refundStatus,
        refundType: fields.refundType
      }

      const dateTypeTimeMap = {
        0: {},
        1: {}
      }

      if (fields.rangeTime) {
        dateTypeTimeMap[fields.dateType] = {
          start: fields.rangeTime[0] ? fields.rangeTime[0].format('YYYY-MM-DD 00:00:00') : '',
          end: fields.rangeTime[1] ? fields.rangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
        }

        params.refundStartDate = dateTypeTimeMap[0].start
        params.refundEndDate = dateTypeTimeMap[0].end

        params.departureDateStart = dateTypeTimeMap[1].start
        params.departureDateEnd = dateTypeTimeMap[1].end
      }

      const idTypeMap = {
        0: '',
        1: ''
      }

      idTypeMap[fields.idType] = fields.certInfo

      params.certID = idTypeMap[0]
      params.passengerName = idTypeMap[1]

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    handleFieldValidate (rule, value, callback) {
      // validate(
      //   'search-form',
      //   this.bookForm.getFieldsValue(),
      //   rule.fullField
      // ).then(({ valid, error }) => {
      //   if (valid) {
      //     callback()
      //   } else {
      //     callback(error)
      //   }
      // })
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.$refs.citySelector.reset()
      this.searchForm.resetFields()
    },
    onDepChange (city) {
      this.cities.depCity = city
    },
    onArrChange (city) {
      this.cities.arrCity = city
    }
  },
  filters: {
    refundStatus,
    refundType
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
