import zhCn from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data () {
    return {
      local: zhCn,
      pageOptions: {
        showSizeChanger: true,
        showQuickJumper: true
      }
    }
  }
}
