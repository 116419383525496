// 简单封装用于表单列表的城市选择组件
<template>
  <span>
    <j-flight-city-selector
      ref="depSelector"
      placeholder="拼音/汉字/三字码"
      size="small"
      class="city-selector-inline"
      :value="depCity"
      @change="onDepChange"
    />
    <span class="m-0-10">-</span>
    <j-flight-city-selector
      ref="arrSelector"
      placeholder="拼音/汉字/三字码"
      size="small"
      class="city-selector-inline"
      :value="arrCity"
      @change="onArrChange"
    />
  </span>
</template>

<script>
export default {
  props: {
    depCity: String,
    arrCity: String
  },
  methods: {
    onDepChange (city) {
      this.$emit('depChange', city)
    },
    onArrChange (city) {
      this.$emit('arrChange', city)
    },
    reset () {
      this.$refs.depSelector.code = ''
      this.$refs.arrSelector.code = ''
      this.$emit('depChange', '')
      this.$emit('arrChange', '')
    }
  }
}
</script>

<style>
</style>
